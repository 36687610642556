<template>
    <div ref="difCompare" class="h-100"></div>
</template>
<script setup lang="ts">
    import {ref,onMounted, watch,onUpdated} from 'vue';
    import getCompare from 'o365.vue.modules.CodeCompare.ts';
    interface IFile{
        code:string,
        fileName:string,
        id?:string
    }
    interface IOptions{
        source1:IFile,
        source2:IFile
    }

    const props = defineProps<IOptions>();

    const difCompare = ref(null);

   /* watch(props,(newVal)=>{
         getCompare({
            element:difCompare.value,
            models:newVal
        });
    })*/

    onMounted(()=>{
        getCompare({
            element:difCompare.value,
            models:props
        });
    });

     onUpdated(()=>{
         getCompare({
            element:difCompare.value,
            models:props
        });;
    })

   
</script>